import React from "react";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

const buttonStyles = {
  fontWeight: 'bold',
  borderRadius: '5px',
  minHeight: '24px',
  padding: '2px 6px',
  fontSize: '0.75rem',
  lineHeight: '1.5',
};

const AccessKeyButton = ({classNameCSS=''}) => {
  return (
    <NavLink to="/Sign In" style={{ textDecoration: "none" }}>
    <Button
      variant="outlined"
      sx={buttonStyles}
    >
      <span className="material-icons" style={{color:"#ff5a3f"}}>&#xe0da;</span>
    </Button>
  </NavLink>
  );
};

export default AccessKeyButton;
